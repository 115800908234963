/**

 * @author wangyihe

 * @date 2021-07-12 15:24

 */

import request from '@/utils/request';

//获取表单元数据
export function formResourceDetail(params) {
  return request({
    url: '/flowableFormResource/detail',
    method: 'get',
    params: params
  });
}

//获取实例中的表单元数据
export function globalFormData(params) {
  return request({
    url: '/flowableForm/globalFormData',
    method: 'get',
    params: params
  });
}

//获取获取实例中的表单元数据中的表单数据
export function getAndLoadData(params) {
  return request({
    url: '/flowableInstance/formData',
    method: 'get',
    params: params
  });
}


//获取发布表单
export function startFormData(params) {
  return request({
    url: '/flowableForm/startFormData',
    method: 'get',
    params: params
  });
}

//发布流程
export function publishFormData(data) {
  return request({
    url: '/flowableHandleTask/start',
    method: 'post',
    data: data
  });
}

//设计，保存表单
export function publishDesignForm(data) {
  return request({
    url: '/flowableFormResource/design',
    method: 'post',
    data: data
  });
}

//获取任务的表单信息
export function taskFormData(params) {
  return request({
    url: '/flowableForm/taskFormData',
    method: 'get',
    params: params
  });
}

//根据任务id查询当前活动节点按钮
export function buttonTrace(params) {
  return request({
    url: '/flowableButton/trace',
    method: 'get',
    params: params
  });
}

//任务办理的表单内容数据
export function taskData(params) {
  return request({
    url: '/flowableHandleTask/taskData',
    method: 'get',
    params: params
  });
}

//任务办理_提交
export function submitForm(data) {
  return request({
    url: '/flowableHandleTask/submit',
    method: 'post',
    data: data
  });
}

//任务办理_退回
export function backForm(data) {
  return request({
    url: '/flowableHandleTask/back',
    method: 'post',
    data: data
  });
}

//查询流程实例的历史任务节点，用于退回时选择节点
export function flowableInstanceHisUserTasks(params) {
  return request({
    url: '/flowableInstance/hisUserTasks',
    method: 'get',
    params: params
  });
}

//查询用户选择下拉
export function userSelector(params) {
  return request({
    url: '/sysUser/selector',
    method: 'get',
    params: params
  });
}

//查询角色选择下拉
export function roleSelector(params) {
  return request({
    url: '/sysRole/dropDown',
    method: 'get',
    params: params
  });
}

//任务办理_转办
export function turnForm(data) {
  return request({
    url: '/flowableHandleTask/turn',
    method: 'post',
    data: data
  });
}

//任务办理_委托
export function entrustForm(data) {
  return request({
    url: '/flowableHandleTask/entrust',
    method: 'post',
    data: data
  });
}

//任务办理_终止
export function endForm(data) {
  return request({
    url: '/flowableHandleTask/end',
    method: 'post',
    data: data
  });
}

//任务办理_挂起
export function suspendForm(data) {
  return request({
    url: '/flowableHandleTask/suspend',
    method: 'post',
    data: data
  });
}

//查询跳转节点列表
export function jumpNodeSelector(params) {
  return request({
    url: '/flowableDefinition/userTasks',
    method: 'get',
    params: params
  });
}

//任务办理_跳转
export function jumpForm(data) {
  return request({
    url: '/flowableHandleTask/jump',
    method: 'post',
    data: data
  });
}

//查询加签用户选择列表
export function findAddSignUserSelector(params) {
  return request({
    url: '/flowableInstance/addSignUserSelector',
    method: 'get',
    params: params
  });
}

//查询减签用户选择列表
export function findSubSignUserSelector(params) {
  return request({
    url: '/flowableInstance/deleteSignUserSelector',
    method: 'get',
    params: params
  });
}

//任务办理_加签
export function addSignForm(data) {
  return request({
    url: '/flowableHandleTask/addSign',
    method: 'post',
    data: data
  });
}

//任务办理_减签
export function deleteSignForm(data) {
  return request({
    url: '/flowableHandleTask/deleteSign',
    method: 'post',
    data: data
  });
}