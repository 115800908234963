import axios from 'axios';
import { message } from 'ant-design-vue';
import store from 'storejs';


const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000, // 请求超时时间
  headers: {
    "Content-Type": "application/json",
  }
});

request.interceptors.response.use(response => {
  if (response.data?.success !== true) {
    message({
      title: '操作失败',
      message: response.data?.message,
      type: 'error'
    });
  } else {
    return response.data;
  }
});

request.interceptors.request.use(
  config => {
    config.headers['Authorization'] = store.get("FormVueToken");
    return config
  },
  error => {
    // 将异常返回给用户处理
    return Promise.reject(error)
  }
)

export default request;
